/* globals braintree */

import loggerHelper from '../loggerHelper';
import arrayObjectHelper from '../arrayObjectHelper';
import initBraintreeClient from '../braintree_blue/init';

const DEFAULT_VERIFICATION_AMOUNT = 1;

const errorMessage = (error) => {
  const errorPath = [
    'details',
    'originalError',
    'details',
    'originalError',
    'error',
    'message',
  ];

  return arrayObjectHelper.getNestedObject(error, errorPath) || error.message;
};

const isUnenrolled = (payload) => {
  const status = arrayObjectHelper.getNestedObject(payload, ['threeDSecureInfo', 'status']);

  return status === 'lookup_not_enrolled';
};

const init = async (config, callbacks) => {
  try {
    loggerHelper.info('{chargifyjs} Braintree init');
    const braintreeClient = await initBraintreeClient(config.client_token);
    const braintree3DSClient = await braintree.threeDSecure.create({
      version: 2, // Will use 3DS 2 whenever possible
      client: braintreeClient,
    });

    config.braintreeClient = braintreeClient;
    config.braintree3DSClient = braintree3DSClient;
  } catch ({ message }) {
    callbacks.onError(message, '3D Secure initialization failed');
  }
};

const creditCardTokenizationData = formData => ({
  creditCard: {
    number: formData.number || '',
    cvv: formData.cvv || '',
    expirationMonth: formData.month || '',
    expirationYear: formData.year || '',
    billingAddress: {
      streetAddress: formData.address || '',
      extendedAddress: formData.address2 || '',
      locality: formData.city || '',
      region: formData.state || '',
      postalCode: formData.zip || '',
      countryCodeAlpha2: formData.country || '',
      firstName: formData.firstName || '',
      lastName: formData.lastName || '',
    },
    options: {
      validate: false,
    },
  },
});

const billingAddress = formData => ({
  streetAddress: formData.address || '',
  extendedAddress: formData.address2 || '',
  locality: formData.city || '',
  region: formData.state || '',
  postalCode: formData.zip || '',
  countryCodeAlpha2: formData.country || '',
  givenName: formData.firstName || '',
  surname: formData.lastName || '',
  phoneNumber: formData.phone || '',
});

const verifyCard = (formData, config, callbacks) => {
  const { braintreeClient, braintree3DSClient } = config;
  const amount = formData.threeDSVerificationAmount || config.threeDSVerificationAmount || DEFAULT_VERIFICATION_AMOUNT;

  braintree3DSClient.on('lookup-complete', (lookupData, next) => {
    if (lookupData.requiresUserAuthentication) callbacks.onThreeDsModal(true);
    next();
  }),

  loggerHelper.info('{chargifyjs} 3DS starting verification');
  loggerHelper.info('{chargifyjs} 3DS data:', formData);

  return braintreeClient.request({
    endpoint: 'payment_methods/credit_cards',
    method: 'post',
    data: creditCardTokenizationData(formData),
  }).then((response) => {
    const creditCardResponse = response.creditCards[0];

    return Promise.all([
      braintree3DSClient,
      creditCardResponse.nonce,
      creditCardResponse.details.bin,
    ]);
  }).then(([threeDSInstance, nonce, bin]) => threeDSInstance.verifyCard({
    nonce,
    bin,
    amount,
    email: formData.email || '',
    billingAddress: billingAddress(formData),
  })).then((payload) => {
    const liabilityShifted = payload.threeDSecureInfo.liabilityShifted;
    const liabilityShiftPossible = payload.threeDSecureInfo.liabilityShiftPossible;
    const eciFlag = payload.threeDSecureInfo.eciFlag;

    if (!liabilityShifted && isUnenrolled(payload)) {
      callbacks.onSuccess(config.unenrolled_token);
    } else if (liabilityShifted) {
      callbacks.onSuccess(payload.nonce);
    } else if (liabilityShiftPossible) {
      const errorDescription = `The customer authentication failed. Please try again or use another payment method. ECI flag: ${eciFlag}`;
      callbacks.onError(errorDescription, '3DS authentication failed.');
    } else {
      const errorDescription = `The customer cannot be authenticated. ECI flag: ${eciFlag}`;
      callbacks.onError(errorDescription, '3DS authentication failed.');
    }
  })
    .catch((error) => {
      const errorDescription = errorMessage(error);
      callbacks.onError(errorDescription, '3DS verification error.');
    });
};

export default {
  init,
  verifyCard,
};
